        <template>
        <div class="content">
            <div class="main">
            <div class="main-r">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="公告通知" name="first">
                    <div class="section">
                    <ul class="ul-top">
                        <li>
                        <p class="pl">热烈庆祝孔雀广告网隆重上线，孔雀广告网，消费多少送多少~</p>
                        <p class="pr">2019-02-02</p>
                        </li>
                        <li>
                        <p class="pl">热烈庆祝孔雀广告网隆重上线，孔雀广告网，消费多少送多少~</p>
                        <p class="pr">2019-02-02</p>
                        </li>
                        <li>
                        <p class="pl">热烈庆祝孔雀广告网隆重上线，孔雀广告网，消费多少送多少~</p>
                        <p class="pr">2019-02-02</p>
                        </li>
                        <li>
                        <p class="pl">热烈庆祝孔雀广告网隆重上线，孔雀广告网，消费多少送多少~</p>
                        <p class="pr">2019-02-02</p>
                        </li>
                        <li>
                        <p class="pl">热烈庆祝孔雀广告网隆重上线，孔雀广告网，消费多少送多少~</p>
                        <p class="pr">2019-02-02</p>
                        </li>
                        <li>
                        <p class="pl">热烈庆祝孔雀广告网隆重上线，孔雀广告网，消费多少送多少~</p>
                        <p class="pr">2019-02-02</p>
                        </li>
                        <li>
                        <p class="pl">热烈庆祝孔雀广告网隆重上线，孔雀广告网，消费多少送多少~</p>
                        <p class="pr">2019-02-02</p>
                        </li>
                        <li>
                        <p class="pl">热烈庆祝孔雀广告网隆重上线，孔雀广告网，消费多少送多少~</p>
                        <p class="pr">2019-02-02</p>
                        </li>
                    </ul>
                    </div>
                </el-tab-pane>

                <el-tab-pane label="交易提醒" name="second">
                    <div class="section">
                    <ul class="ul-top">
                        <li>
                        <p class="pl">您的比价号：kd55378474比价单有新进度了~</p>
                        <p class="pr">2019-02-02</p>
                        </li>
                        <li>
                        <p class="pl">您的订单号：kd55378474比价单有新进度了~</p>
                        <p class="pr">2019-02-02</p>
                        </li>
                        <li>
                        <p class="pl">您发布的需求：制作门头，需要上门安装，有新的商家参与报价，快看看吧！</p>
                        <p class="pr">2019-02-02</p>
                        </li>
                        <li>
                        <p class="pl">您的订单号：356544545订单已发货，请注意查收！</p>
                        <p class="pr">2019-02-02</p>
                        </li>
                        <li>
                        <p class="pl">您的比价号：kd55378474比价单有新进度了~</p>
                        <p class="pr">2019-02-02</p>
                        </li>
                        <li>
                        <p class="pl">您的订单号：kd55378474比价单有新进度了~</p>
                        <p class="pr">2019-02-02</p>
                        </li>
                    </ul>
                    </div>
                </el-tab-pane>
                </el-tabs>
                <!-- 以上是分页 -->
                <div class="pagination">
                <div class="block">
                    <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage4"
                    :page-sizes="[100, 200, 300, 400]"
                    :page-size="100"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="400"
                    ></el-pagination>
                </div>
                <!-- 以上是分页 -->
                </div>
            </div>
            </div>
        </div>
        </template>

        <script>
        import Vue from "vue";
        import Header from "@/components/common/header.vue";
        import Footer from "@/components/common/footer.vue";
        import Aside from "@/components/common/aside.vue";
        import Swiper from "swiper";
        // import '@/swiper/css/swiper.min.css';

        Vue.filter("fil", function(info) {
        //过滤
        return "共" + info + "件产品"; //把item这个原本的info数据加上件产品返回出去
        });

        export default {
        name: "index",
        components: { Header, Footer, Aside }, //注册组件
        data() {
            return {
            radio1: "上海",
            radio2: "上海",
            num1: 5,
            show: true,
            shows: false,
            info: "20",
            activeName: "",
            info1: "全部购买订单",
            flagSelect: false,
            activeName: "second",

            currentPage1: 5, //
            currentPage2: 5,
            currentPage3: 5,
            currentPage4: 4
            };
        },
        methods: {
            showCont() {
            this.show == true, (this.shows = false);
            },
            showConts() {
            this.show == false, (this.shows = true);
            },
            handleClick() {

            },
            btn1() {
            this.flagSelect = true;
            },
            handleSizeChange(val) {
            //分页
            console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            },
            priceDetail() {
            this.$router.push("/priceDetail"); //调转到比价详情
            }
        },
        mounted() {
            var galleryThumbs = new Swiper(".gallery-thumbs", {
            spaceBetween: 4,
            slidesPerView: 4,
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true
            });
            var galleryTop = new Swiper(".gallery-top", {
            spaceBetween: 4,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            },
            thumbs: {
                swiper: galleryThumbs
            }
            });
        }
        };
        </script>

        <style lang="scss" scoped>
        @import "../../../assets/scss/common";
        @mixin icon($m) {
        $url: "../../../assets/image/" + $m;
        @include bgImage($url);
        background-size: contain;
        }
        * {
        margin: 0;
        padding: 0;
        }
        h2,
        ul,
        li {
        margin: 0;
        padding: 0;
        }
        a {
        text-decoration: none;
        }
        li {
        list-style: none;
        }
        .content {
        width: 100%;
        height: 1192px;
        background: #f5f5f5;
        font-size: 15px;
        /deep/ .main {
            width: 1200px;
            height: 1129px;
            margin: 0 auto;
            font-size: 15px;

            .main-l {
            width: 181px;
            height: 1129px;
            background: #fff;
            float: left;
            }
            /deep/ .main-r {
            width: 1000px;
            height: 990px;
            background: #fff;

            .section {
                width: 920px;
                height: 100%;

                margin: 0 auto;
                .ul-top {
                width: 100%;
                height: 100%;

                li {
                    width: 100%;
                    height: 60px;
                    border-bottom: 1px dashed #e5e5e5;
                    .pl {
                    width: 520px;
                    line-height: 60px;
                    float: left;
                    font-size: 16px;
                    color: #333333;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    }
                    .pr {
                    width: 200px;
                    line-height: 60px;
                    float: right;
                    font-size: 16px;
                    color: #333333;
                    text-align: right;
                    }
                }
                }
            }

            // ------以下是头部tab切换导航样式-----
            .el-tabs__nav {
                margin-left: 40px;
            }
            .el-tabs el-tabs--top {
                margin-top: 20px;
            }
            .el-tabs__header {
                margin-top: 20px;
            }
            .el-tabs__item:hover {
                color: black !important;
            }
            .el-tabs__active-bar {
                background: #0db168;
            }
            .el-tabs__item.is-active {
                color: black;
            }
            .el-tabs__nav-wrap::after {
                height: 0;
            }
            #tab-first {
                background: none;
            }
            // ------以上是头部tab切换导航样式-----

            .pagination {
                width: 537px;
                height: 40px;
                margin: 0 auto;
                margin-top: 60px;
            }
            //以上是分页器样式
            }
        }
        }
        </style>
