<template>
    <div class="genera">
        <div class="title">我的推广</div>
        <div class="fanswrap">
            <div class="fanbox">
                <img src="../../../assets/image/wodefensishu.png" alt="" srcset="">
                <div class="world">
                    <span>我的粉丝数</span>
                    <div class="num">{{size}}</div>
                </div>
            </div>
            <div class="fanbox">
                <img src="../../../assets/image/leijijianglijifen.png" alt="" srcset="">
                <div class="world">
                    <span>累计奖励积分</span>
                    <div class="num">{{size*10}}</div>
                </div>
            </div>
        </div>
        <ul class="itemwrap">
            <li>序号</li>
            <li>注册手机号</li>
            <li>注册时间</li>
            <li>奖励积分</li>
        </ul>
        <template v-for="item,index in list">
            <ul class="itemwrap item">
                <li>{{index+1}}</li>
                <li>{{item.register | phoneFmt}}</li>
                <li>{{item.createDate}}</li>
                <li>{{item.tgjf}}</li>
            </ul>
        </template>
        <div class="pagination">
            <div class="block">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage4"
                        :page-sizes="[5, 10, 15, 20]"
                        :page-size="8"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalSize"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import * as api from '../../../api/api'

    export default {
        name: 'genera',
        data() {
            return {
                list: [],//列表数据
                totalSize: 0, //一共几条数据
                currentPage4: 1, //前往第几页默认展示去往第一页，在结构中需绑定
                page: 1, //刷新页面，展示第一页，每页5行
                rows: 5,
                size: 0,
                mobile: this.$store.getters.user.mobile,
                generalize: ""//推广链接
            };
        },
        components: {},
        methods: {
            getDatas() {//获取页面数据
                var _that = this;
                var param = {phone: _that.mobile, page: _that.page, rows: _that.rows};
                api.getMyfs(param).then(res => {
                    var code = res.data.code;
                    if (code === 200 || code === 0) {
                        _that.list = res.data.data.data;
                        _that.totalSize = res.data.data.totalSize;
                        _that.size = _that.list.length;
                    }
                });
            },
            handleSizeChange(val) {//分页
                console.log(`每页 ${val} 条`);
                //分页
                this.rows = `${val}` //每页多少条
                this.getDatas();
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
                this.page = `${val}`; //一共几页
                this.getDatas();
            },
            getGeneralize() {
                var _that = this;
                var param = {phone: _that.mobile};
                api.generalize(param).then(res => {
                    var code = res.data.code;
                    if (code === 200 || code === 0) {
                        this.generalize = res.data.data;
                    }
                });
            }
        },
        created() {
            var _that = this;
            _that.getDatas();
            //获取推广链接
            // _that.getGeneralize();
        },
    }
</script>

<style lang='scss' scoped>
    @import "../../../assets/scss/common.scss";
    @import "../../../assets/scss/reset.scss";

    $theme-color: #0db168;
    li {
        list-style: none;
    }

    .genera {
        padding: 30px 20px;

        .title {
            font-size: 18px;
        }

        .bgwrap {
            border: 1px solid $theme-color;
            background: #e6f7f0;
            padding: 13px 20px;
            font-size: 15px;
            color: #333333;
            margin: 15px 0 20px;

            .name {
                margin-bottom: 10px;
            }

            .more {
                color: $theme-color;
            }
        }

        .fanswrap {
            padding-bottom: 20px;
            border-bottom: 1px solid #eaeaea;
            overflow: hidden;

            .fanbox {
                width: 233px;
                float: left;
                overflow: hidden;
                padding-left: 12px;

                > img {
                    float: left;
                    padding-right: 22px;
                }

                .world {
                    float: left;
                    text-align: center;

                    .num {
                        font-size: 18px;
                        margin-top: 10px;
                    }
                }
            }
        }

        .titles {
            font-size: 16px;
            margin: 24px 0 20px;
        }

        .rewrap {
            overflow: hidden;

            > div {
                float: left;
            }

            .graybg {
                width: 498px;
                height: 72px;
                background: #eaeaea;
                font-size: 16px;
                color: #666666;
                padding: 18px;

                .nas {
                    margin-bottom: 10px;
                }

                .gren {
                    color: $theme-color;
                }
            }

            .code {
                width: 105px;
                font-size: 15px;
                color: $theme-color;
                text-align: center;
                margin: 0 28px 0 30px;

                .greenbg {
                    width: 100px;
                    height: 100px;
                    background: $theme-color;
                    margin: 0 auto 10px;;

                    > img {
                        width: 88px;
                        height: 88px;
                        margin-top: 5px;
                    }
                }
            }

            .sao {
                font-size: 14px;
                padding-top: 35px;

                .logo {
                    margin-top: 10px;
                }
            }
        }

        .copy {
            color: #ffffff;
            background: $theme-color;
            margin-bottom: 20px;
        }

        .itemwrap {
            overflow: hidden;
            padding: 0;
            margin: 0;
            background: #eaeaea;
            font-size: 15px;
            color: #666666;

            > li {
                float: left;
                width: 230px;
                text-align: center;
                height: 50px;
                line-height: 50px;
            }
        }

        .item {
            background-color: #ffffff;
            border: 1px solid #eaeaea;
            border-top: none;

            > li {
                height: 60px;
                line-height: 60px;
            }
        }

        .pagination {
            width: 537px;
            height: 40px;
            margin: 60px auto 0;
        }
    }
</style>
